<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <h3 class="mb-5 text-center">Orders Panel</h3>

          <div v-if="message" :class="messageClass" class="alert w-100" role="alert" style="position: fixed !important">
            {{ message }}
          </div>

          <table v-if="orders.length" class="table table-striped table-hover">
            <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Client Name</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <!-- Display Orders -->
            <tr v-for="(order, index) in orders" :key="order.invoice_id">
              <th scope="row">{{ order.invoice_id }}</th>
              <td>{{ order.first_name }} {{ order.middle_name }} {{ order.last_name }}</td>
              <td>{{ order.user_first_name }} {{ user.user_middle_name }} {{ user.user_last_name }}</td>
              <td>
                <button class="btn btn-toggle" @click="toggleCart(index)">
                  {{ order.showCarts ? 'Hide Carts' : 'Show Carts' }}
                </button>
              </td>

              <template v-if="order.showCarts">
                <tr>
                  <td colspan="4">
                    <table class="table cart-table">
                      <thead>
                      <tr>
                        <th>Product</th>
                        <th>SKU</th>
                        <th>Sale Price</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="cart in order.carts" :key="cart.cart_id">
                        <td>{{ cart.title }}</td>
                        <td>{{ cart.sku }}</td>
                        <td>
                          <input type="number" v-model="cart.sale_price"
                                 @change="updateSalePrice(cart.cart_id, cart.sale_price)"
                                 class="form-control" min="0" style="width: 180px !important"/>
                        </td>
                        <td>
                          <button class="btn btn-primary" @click="updateSalePrice(cart.cart_id, cart.sale_price)">
                            Save
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </template>
            </tr>
            </tbody>
          </table>

          <!-- If no orders, show a loading state or message -->
          <p v-else class="loading-message text-center">No orders...</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';
import { useUserStore } from "../../../../../store";

export default {
  name: "AffiliateRevenue",
  setup() {
    const store = useUserStore();
    const user = store.user;
    return {
      user,
    };
  },
  data() {
    return {
      orders: [], // Initialize orders array
      message: '', // Message for success/failure
      messageClass: '' // Class for message styling
    };
  },

  created() {
    this.userLoad(); // Load user data on component creation
  },

  methods: {
    userLoad() {
      const page = `api/invoices-to-update?user_id=${this.user?.id}`;
      axiosInstance.get(page)
          .then(({ data }) => {
            // Filter out orders with empty carts
            if (Array.isArray(data)) {
              this.orders = data
                  .filter(order => order.carts.length > 0) // Only keep orders with carts
                  .map(order => ({
                    ...order,
                    showCarts: false, // Initialize showCarts for each order
                    carts: order.carts || [] // Ensure carts is always an array
                  }));
            }
          })
          .catch(error => {
            console.error('Error fetching data:', error); // Log any errors
          });
    },

    // Toggle function to show/hide carts
    toggleCart(index) {
      const order = this.orders[index];
      if (order) { // Check if order is defined
        // Safely toggle the showCarts property
        order.showCarts = !order.showCarts;
      } else {
        console.warn('Order is undefined at index:', index);
      }
    },

    // Update the sale price of the cart item
    updateSalePrice(cartId, newPrice) {
      const payload = {
        cart_id: cartId,
        sale_price: newPrice,
      };

      axiosInstance.post('api/update-cart-price', payload)
          .then(response => {
            console.log('Price updated successfully:', response.data);
            this.showMessage('Price updated successfully!', 'alert-success');
          })
          .catch(error => {
            console.error('Error updating price:', error);
            this.showMessage('Failed to update price. Please try again.', 'alert-danger');
          });
    },

    // Method to show success or failure message
    showMessage(msg, msgClass) {
      this.message = msg;
      this.messageClass = msgClass;

      // Clear the message after 3 seconds
      setTimeout(() => {
        this.message = '';
        this.messageClass = '';
      }, 3000);
    }
  },
};
</script>

<style scoped>
.card {
  border: 1px solid #ddd;
  margin-bottom: 20px;
  border-radius: 5px;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}

.btn-toggle {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-toggle:hover {
  background-color: #0056b3;
}

.cart-table {
  margin-top: 10px;
  width: 100%;
}

.cart-container {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f8f9fa;
}

.loading-message {
  font-size: 1.2em;
  color: #888;
}

.alert {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}
</style>
