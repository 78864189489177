<script>
import axiosInstance from '../../../axiosInstance';

export default {
  data() {
    return {
      selectedBrand: null,
      productDetail: [],
      product: {
        total_commission: '',
        affiliate_commission: '',
        sub_affiliate_commission: '',
      },
      brands: [],

      products: [],
      childcategory: [],
      file: null
    };
  },

  created() {
    this.fetchAllData(); // Load data when component is created
  },

  methods: {
    fetchAllData() {
      return axiosInstance
          .get('api/get-product-detail-commission')
          .then(response => {
            this.productDetail = response.data;
          })
          .catch(error => {
            console.error('Error fetching main data:', error);
          });
    },

  }
};
</script>

<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Product Detail</h3>


          <table class="table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Image</th>
              <th>Sku</th>
              <th>Total Commission</th>
              <th>Affiliate Commission</th>
              <th>Sub Affiliate Commission</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="product in productDetail" :key="product.id">
              <td>{{ product.id }}</td>
              <td>{{ product.name }}</td>
              <td><img :src="'https://api.gocami.com/product_detail/' + product.image" width="100"/></td>
              <td>{{ product.sku }}</td>
              <td>{{product.total_commissions}}</td>
              <td>{{product.affiliate_commission}}
              </td>
              <td>{{product.sub_affiliate_commission}}</td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</template>
