<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <h3 class="mb-5">Orders Panel</h3>

          <!-- Success or Error Message -->
          <div v-if="message" :class="{'alert alert-success': isSuccess, 'alert alert-danger': !isSuccess}">
            {{ message }}
          </div>

          <div class="mb-3">
            <label for="search">Search:</label>
            <input type="text" v-model="filters.search" id="search" class="form-control"
                   placeholder="Search by phone or name"/>

            <button @click="applySearchFilter" class="btn btn-primary mt-3" :disabled="!isSearchValid">Filter</button>
          </div>

          <!-- Display Orders Table only after filter is applied and data exists -->
          <div v-if="allOrder.length > 0" class="tab-content" id="orderTabsContent">
            <div class="tab-pane active">
              <table class="table w-100">
                <thead>
                <tr>
                  <th>#</th>
                  <th>User Name</th>
                  <th>Phone</th>
                  <th>Created at</th>
                  <th>Address</th>
                  <th>Location</th>
                  <th>Total</th>
                  <th>Aramex Total</th>
                  <th>Delivery</th>
                  <th>Payment</th>
                  <th>Payment Date</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="order in allOrder" :key="order.id">
                  <th scope="row">{{ order.id }}</th>
                  <td>{{ order.first_name }} {{ order.middle_name }} {{ order.last_name }}</td>
                  <td>{{ order.phone }}</td>
                  <td>{{ order.created_at }}</td>
                  <td>{{ order.address }}</td>
                  <td>{{ order.governorates_name }} - {{ order.city_name }} - {{ order.area_name }}</td>
                  <td>{{ order.total }}</td>
                  <td>
                    <input
                        type="number"
                        v-model="order.total_receivable"
                        class="form-control"
                        style="width:250px !important"
                    />
                  </td>
                  <td>
                    <input
                        type="number"
                        v-model="order.delivery_price"
                        class="form-control"
                        style="width:250px !important"
                    />
                  </td>
                  <td>
                    <select class="form-select" v-model="order.payment">
                      <option selected disabled>Payment</option>
                      <option value="cash">Cash</option>
                      <option value="online">in store</option>
                      <option value="online">Wish</option>
                      <option value="online">Omt</option>
                    </select>
                  </td>
                  <td>
                    <input type="date" class="form-control" v-model="order.payment_date"/>
                  </td>
                  <td>
                    <button
                        type="button"
                        class="btn btn-dark"
                        @click="updateBarcode(order.id, order.total_receivable, order.delivery_price, order.payment, order.payment_date)"
                    >
                      Update
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Message when no orders are available -->
          <div v-else-if="allOrder.length === 0 && hasFiltered" class="alert alert-warning">
            No orders found for the provided search criteria.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axiosInstance from '../../../axiosInstance';

export default {
  name: 'OrderTracking',

  data() {
    return {
      allOrder: [],
      filters: {
        search: '',
        date: '',      // Add date filter
        delivery: '',  // Add delivery filter
      },
      message: '',
      isSuccess: true,
      hasFiltered: false,
    };
  },


  computed: {
    // Ensure that search input is non-empty
    isSearchValid() {
      return this.filters.search.trim().length > 0;
    }
  },

  methods: {
    orderLoad() {
      axiosInstance.get('api/order-tracking-management-completed', {
        params: this.filters  // Send all filters, including date and delivery
      }).then(({data}) => {
        this.allOrder = data;
        this.hasFiltered = true;
      }).catch(error => {
        console.error('Error loading orders:', error);
        this.hasFiltered = true;
      });
    },


    applySearchFilter() {
      if (this.isSearchValid) {
        this.orderLoad();
      } else {
        this.allOrder = []; // Clear the results if no valid search input
        this.hasFiltered = true;
      }
    },

    updateBarcode(id, total_receivable, delivery_price, payment, payment_date) {
      axiosInstance.post('api/update-invoice-management-completed', {
        id: id,
        total_receivable: total_receivable,
        delivery_price: delivery_price,
        payment: payment,
        payment_date: payment_date,
      }).then(() => {
        this.message = `Total for order #${id} updated successfully!`;
        this.isSuccess = true;
        this.orderLoad();
      }).catch(error => {
        console.error('Error updating total:', error);
        this.message = error.response?.data?.error || `Failed to update Total for order #${id}.`;
        this.isSuccess = false;
      });
    },
  }
};
</script>


<!--<template>-->
<!--  <div class="main-panel">-->
<!--    <div class="content-wrapper">-->
<!--      <div class="card">-->
<!--        <div class="card-body">-->
<!--          <h3 class="mb-5">Orders Panel</h3>-->

<!--          &lt;!&ndash; Success or Error Message &ndash;&gt;-->
<!--          <div v-if="message" :class="{'alert alert-success': isSuccess, 'alert alert-danger': !isSuccess}">-->
<!--            {{ message }}-->
<!--          </div>-->

<!--          <div class="mb-3">-->
<!--            <label for="search">Search:</label>-->
<!--            <input type="text" v-model="filters.search" id="search" class="form-control" placeholder="Search by phone or name" @blur="applySearchFilter" />-->

<!--&lt;!&ndash;            <label for="from_date" class="mt-2">From Date:</label>&ndash;&gt;-->
<!--&lt;!&ndash;            <input type="date" v-model="filters.from_date" id="from_date" class="form-control" />&ndash;&gt;-->

<!--&lt;!&ndash;            <label for="to_date" class="mt-2">To Date:</label>&ndash;&gt;-->
<!--&lt;!&ndash;            <input type="date" v-model="filters.to_date" id="to_date" class="form-control" />&ndash;&gt;-->

<!--            <button @click="applyDateFilter" class="btn btn-primary mt-3" :disabled="!filters.from_date">Filter</button>-->
<!--          </div>-->

<!--          &lt;!&ndash; Display Orders Table only if from_date is selected &ndash;&gt;-->
<!--          <div v-if="filters.from_date && allOrder.length > 0" class="tab-content" id="orderTabsContent">-->
<!--            <div class="tab-pane active">-->
<!--              <table class="table w-100">-->
<!--                <thead>-->
<!--                <tr>-->
<!--                  <th>#</th>-->
<!--                  <th>User Name</th>-->
<!--                  <th>Phone</th>-->
<!--                  <th>Created at</th>-->
<!--                  <th>Address</th>-->
<!--                  <th>Location</th>-->
<!--                  <th>Total</th>-->
<!--                  <th>Aramex Total</th>-->
<!--                  <th>Delivery</th>-->
<!--                  <th>Action</th>-->
<!--                </tr>-->
<!--                </thead>-->
<!--                <tbody>-->
<!--                <tr v-for="order in allOrder" :key="order.id">-->
<!--                  <th scope="row">{{ order.id }}</th>-->
<!--                  <td>{{ order.first_name }} {{ order.middle_name }} {{ order.last_name }}</td>-->
<!--                  <td>{{ order.phone }}</td>-->
<!--                  <td>{{ order.created_at }}</td>-->
<!--                  <td>{{ order.address }}</td>-->
<!--                  <td>{{ order.governorates_name }} - {{ order.city_name }} - {{ order.area_name }}</td>-->
<!--                  <td>{{ order.total }}</td>-->
<!--                  <td>-->
<!--                    <input-->
<!--                        type="number"-->
<!--                        v-model="order.total_receivable"-->
<!--                        class="form-control"-->
<!--                        style="width:250px !important"-->
<!--                    />-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <input-->
<!--                        type="number"-->
<!--                        v-model="order.delivery_price"-->
<!--                        class="form-control"-->
<!--                        style="width:250px !important"-->
<!--                    />-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <button-->
<!--                        type="button"-->
<!--                        class="btn btn-dark"-->
<!--                        @click="updateBarcode(order.id, order.total_receivable, order.delivery_price)"-->
<!--                    >-->
<!--                      Update-->
<!--                    </button>-->
<!--                  </td>-->
<!--                </tr>-->
<!--                </tbody>-->
<!--              </table>-->
<!--            </div>-->
<!--          </div>-->

<!--          &lt;!&ndash; Message when no orders are available &ndash;&gt;-->
<!--          <div v-else-if="filters.from_date && allOrder.length === 0" class="alert alert-warning">-->
<!--            No orders found for the selected date range.-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--import axiosInstance from '../../../axiosInstance';-->

<!--export default {-->
<!--  name: 'OrderTracking',-->

<!--  data() {-->
<!--    return {-->
<!--      currentTab: 'allOrders',-->
<!--      allOrder: [],-->
<!--      filters: {-->
<!--        from_date: '',-->
<!--        to_date: '',-->
<!--        search: '',-->
<!--      },-->
<!--      message: '',-->
<!--      isSuccess: true,-->
<!--      debounceTimeout: null,-->
<!--    };-->
<!--  },-->

<!--  methods: {-->
<!--    orderLoad() {-->
<!--      if (this.filters.from_date) {-->
<!--        axiosInstance.get('api/order-tracking-management-completed', {-->
<!--          params: this.filters-->
<!--        }).then(({ data }) => {-->
<!--          this.allOrder = data;-->
<!--        }).catch(error => {-->
<!--          console.error('Error loading orders:', error);-->
<!--        });-->
<!--      }-->
<!--    },-->

<!--    applyDateFilter() {-->
<!--      this.orderLoad();-->
<!--    },-->

<!--    applySearchFilter() {-->
<!--      this.orderLoad();-->
<!--    },-->

<!--    updateBarcode(id, total_receivable, delivery_price) {-->
<!--      axiosInstance.post('api/update-invoice-management-completed', {-->
<!--        id: id,-->
<!--        total_receivable: total_receivable,-->
<!--        delivery_price: delivery_price,-->
<!--      }).then(() => {-->
<!--        this.message = `Total for order #${id} updated successfully!`;-->
<!--        this.isSuccess = true;-->
<!--        this.orderLoad();-->
<!--      }).catch(error => {-->
<!--        console.error('Error updating total:', error);-->
<!--        this.message = error.response?.data?.error || `Failed to update Total for order #${id}.`;-->
<!--        this.isSuccess = false;-->
<!--      });-->
<!--    },-->
<!--  }-->
<!--};-->
<!--</script>-->

<style scoped>
.nav-link.active {
  background-color: #007bff;
  color: white;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}
</style>
