<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <h4 class="mb-4">{{ aramexInvoiceName }}</h4> <!-- Display the Aramex Invoice Name -->

          <!-- Print Button -->
          <button @click="printInvoice" class="btn btn-secondary mb-3">Print Invoice</button>

          <table class="table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Location</th>
              <th>Address</th>
              <th>Barcode</th>
              <th>Date</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="order in allOrder" :key="order.id">
              <th scope="row">{{ order.id }}</th>
              <td>{{ order.first_name }} {{ order.middle_name }} {{ order.last_name }}</td>
              <td>{{ order.governorates_name }} {{ order.city_name }} {{ order.area_name }}</td>
              <td>{{ order.address }}</td>
              <td>{{ order.invoice_barcode }}</td>
              <td>{{ order.created_at }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
  data() {
    return {
      allOrder: [],
      aramexInvoiceName: '', // New property to store the Aramex Invoice Name
    };
  },

  created() {
    this.orderLoad(); // Load orders from the server
  },

  methods: {
    orderLoad() {
      axiosInstance.get(`api/aramex-delivery-invoices-completed/${this.$route.params.id}`)
          .then(({ data }) => {
            // Ensure that the out_warehouse field is correctly set for each order
            this.allOrder = data.map(order => ({
              ...order,
              out_warehouse: order.out_warehouse === 1,
            }));

            // Store the name of the first order (assuming all have the same aramex_invoice_name)
            if (this.allOrder.length > 0) {
              this.aramexInvoiceName = this.allOrder[0].aramex_invoice_name;
            }
          })
          .catch(error => {
            console.error('Error loading orders:', error);
          });
    },

    printInvoice() {
      // Create a new window
      const printWindow = window.open('', '', 'height=600,width=800');
      printWindow.document.write('<html><head><title>Invoice</title>');
      printWindow.document.write('<style>table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid black; padding: 8px; text-align: left; } h4 { text-align: center; }</style>');
      printWindow.document.write('</head><body>');

      // Add the invoice name
      printWindow.document.write('<h4>' + this.aramexInvoiceName + '</h4>');

      // Add the table
      printWindow.document.write('<table><thead><tr><th>#</th><th>Name</th><th>Location</th><th>Address</th><th>Barcode</th><th>Date</th></tr></thead><tbody>');
      this.allOrder.forEach(order => {
        printWindow.document.write('<tr><td>' + order.id + '</td><td>' + order.first_name + ' ' + order.middle_name + ' ' + order.last_name + '</td><td>' + order.governorates_name + ' ' + order.city_name + ' ' + order.area_name + '</td><td>' + order.address + '</td><td>' + order.invoice_barcode + '</td><td>' + order.created_at + '</td></tr>');
      });
      printWindow.document.write('</tbody></table>');
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print(); // Trigger the print dialog
    },

    toggleOutWarehouse(orderId, outWarehouse) {
      axiosInstance.post(`api/toggle-out-warehouse`, {
        id: orderId,
        out_warehouse: outWarehouse
      }).then(() => {
        this.message = `Order #${orderId} updated successfully!`;
        this.isSuccess = true;
      }).catch(error => {
        console.error('Error toggling out_warehouse:', error);
        this.message = error.response?.data?.error || `Failed to update order #${orderId}.`;
        this.isSuccess = false;
      });
    },
  },
};
</script>
<style scoped>
/* Add your custom styles here */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}
</style>
