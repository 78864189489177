<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <h3 class="mb-5">Orders Panel</h3>

          <!-- Success or Error Message -->
          <div v-if="message" :class="{'alert alert-success': isSuccess, 'alert alert-danger': !isSuccess}">
            {{ message }}
          </div>

          <!-- Delivery Selection Dropdown -->
          <div class="mb-3">
            <label for="deliverySelect">Select Delivery:</label>
            <select id="deliverySelect" v-model="selectedDelivery" @change="orderLoad" class="form-control">
              <option value="" disabled>Select a delivery</option>
              <option v-for="delivery in deliveries" :value="delivery.id" :key="delivery.id">{{ delivery.delivery_name }}</option>
            </select>
          </div>

          <!-- Input Field for Barcode -->
          <div v-if="selectedDelivery" class="mb-3">
            <label for="barcodeInput">Scan Invoice Barcode:</label>
            <input
                type="text"
                id="barcodeInput"
                v-model="scannedBarcode"
                @input="handleBarcodeInput"
                class="form-control"
                placeholder="Enter invoice barcode"
            />
          </div>

          <!-- Update Button -->
          <button v-if="selectedDelivery" @click="updateInvoices" class="btn btn-primary">Update Invoices</button>

          <!-- Print Button (Visible only if a delivery is selected and orders are available) -->
          <button v-if="selectedDelivery && allOrder.length > 0" @click="printInvoice" class="btn btn-secondary mt-3">Print Invoice</button>

          <!-- Tabs Content (Display only if delivery is selected) -->
          <div v-if="selectedDelivery && allOrder.length > 0" class="tab-content mt-4" id="orderTabsContent">
            <!-- All Orders Tab -->
            <div class="tab-pane active">
              <table class="table w-100">
                <thead>
                <tr>
                  <th>#</th>
                  <th>User Name</th>
                  <th>Created at</th>
                  <th>Barcode</th>
                  <th>Number of Boxes</th>
                  <th>Scanned Count</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="order in allOrder" :key="order.id">
                  <th scope="row">{{ order.id }}</th>
                  <td>{{ order.first_name }} {{ order.middle_name }} {{ order.last_name }}</td>
                  <td>{{ new Date(order.created_at).toLocaleString() }}</td>
                  <td>{{ order.invoice_barcode }}</td>
                  <td>{{ order.number_of_boxes }}</td>
                  <td><input type="text" :value="order.taxed_number_of_boxes" readonly class="form-control" /></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Message if no orders are available for selected delivery -->
          <div v-if="selectedDelivery && allOrder.length === 0" class="alert alert-info mt-4">
            No invoices available for the selected delivery.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
  name: 'OrderTracking',

  data() {
    return {
      deliveries: [], // Store deliveries
      selectedDelivery: null, // Track selected delivery
      currentTab: 'allOrders',
      allOrder: [], // Store orders for the selected delivery
      message: '',
      isSuccess: true,
      scannedBarcode: '', // Store the scanned barcode
    };
  },

  created() {
    this.orderLoad(); // Load deliveries and orders on component creation
  },

  methods: {
    orderLoad() {
      axiosInstance.get('api/aramex-boxes', {
        params: {
          delivery_id: this.selectedDelivery || null, // Send the selected delivery ID
        }
      })
          .then(({ data }) => {
            this.deliveries = data.deliveries; // Assign deliveries to the dropdown
            this.allOrder = data.aramex; // Assign invoices to the table
            this.loadStoredInvoices(); // Merge with stored invoices
          })
          .catch(error => {
            console.error('Error loading orders:', error);
          });
    },

    loadStoredInvoices() {
      const storedInvoices = JSON.parse(localStorage.getItem('storedInvoices') || '[]');

      storedInvoices.forEach(storedInvoice => {
        const order = this.allOrder.find(order => order.id === storedInvoice.id);
        if (order) {
          order.taxed_number_of_boxes = storedInvoice.taxed_number_of_boxes;
        }
      });
    },

    handleBarcodeInput() {
      const barcode = this.scannedBarcode.trim();

      if (barcode !== '') {
        const matchingOrders = this.allOrder.filter(order => order.invoice_barcode === barcode);

        if (matchingOrders.length > 0) {
          let orderUpdated = false;

          for (const order of matchingOrders) {
            if (!order.taxed_number_of_boxes) {
              order.taxed_number_of_boxes = 0;
            }

            if (order.taxed_number_of_boxes < order.number_of_boxes) {
              order.taxed_number_of_boxes += 1;
              orderUpdated = true;

              this.storeInvoiceLocally(order);
              this.scannedBarcode = ''; // Clear input
              this.message = `Box scanned successfully for invoice: ${barcode}`;
              this.isSuccess = true;
              break;
            }
          }

          if (!orderUpdated) {
            this.message = `Maximum number of boxes already scanned for all invoices with barcode: ${barcode}`;
            this.isSuccess = false;
          }
        } else {
          this.message = `Invoice barcode: ${barcode} not found.`;
          this.isSuccess = false;
        }
      }
    },

    storeInvoiceLocally(order) {
      let storedInvoices = JSON.parse(localStorage.getItem('storedInvoices') || '[]');
      const existingInvoice = storedInvoices.find(storedInvoice => storedInvoice.id === order.id);

      if (existingInvoice) {
        existingInvoice.taxed_number_of_boxes = order.taxed_number_of_boxes;
      } else {
        storedInvoices.push({
          id: order.id,
          taxed_number_of_boxes: order.taxed_number_of_boxes,
        });
      }

      localStorage.setItem('storedInvoices', JSON.stringify(storedInvoices));
    },

    updateInvoices() {
      const invoicesData = this.allOrder.map(order => ({
        id: order.id,
        taxed_number_of_boxes: order.taxed_number_of_boxes
      }));

      const selectedDeliveryName = this.deliveries.find(delivery => delivery.id === this.selectedDelivery)?.delivery_name;

      axiosInstance.post('api/aramex-invoices', {
        invoices: invoicesData,
        delivery_name: selectedDeliveryName,
      })
          .then(response => {
            this.message = response.data.message;
            this.isSuccess = response.data.success;

            if (this.isSuccess) {
              localStorage.removeItem('storedInvoices');
            }
          })
          .catch(error => {
            this.message = 'Error updating invoices: ' + error.message;
            this.isSuccess = false;
          });
    },

    printInvoice() {
      const selectedDeliveryName = this.deliveries.find(delivery => delivery.id === this.selectedDelivery)?.delivery_name;

      // Create print window
      const printWindow = window.open('', '', 'height=600,width=800');
      printWindow.document.write('<html><head><title>Print Invoice</title>');

      // Add minimal styles
      printWindow.document.write('<style>table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid black; padding: 8px; text-align: left; } h4 { text-align: center; margin-bottom: 20px; }</style>');
      printWindow.document.write('</head><body>');

      // Add the selected delivery name
      printWindow.document.write('<h4>' + selectedDeliveryName + '</h4>');

      // Add table content including Scanned Count
      printWindow.document.write('<table><thead><tr><th>#</th><th>User Name</th><th>Created at</th><th>Barcode</th><th>Number of Boxes</th><th>Scanned Count</th></tr></thead><tbody>');

      this.allOrder.forEach(order => {
        printWindow.document.write(
            '<tr>' +
            '<td>' + order.id + '</td>' +
            '<td>' + order.first_name + ' ' + order.middle_name + ' ' + order.last_name + '</td>' +
            '<td>' + new Date(order.created_at).toLocaleString() + '</td>' +
            '<td>' + order.invoice_barcode + '</td>' +
            '<td>' + order.number_of_boxes + '</td>' +
            '<td>' + (order.taxed_number_of_boxes || 0) + '</td>' + // Display Scanned Count
            '</tr>'
        );
      });

      printWindow.document.write('</tbody></table>');
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print(); // Trigger the print dialog
    }

  },
};
</script>


<style scoped>
.nav-link.active {
  background-color: #007bff;
  color: white;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}
</style>
