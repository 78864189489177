<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <select v-model="selectedBrand" @change="loadProductsByBrand">
            <option v-for="brand in brands" :key="brand.id" :value="brand.id">
              {{ brand.brand_name }}
            </option>
          </select>

          <h3 class="mb-5">Product Detail</h3>

          <button @click="updateCheckedItems" class="btn btn-primary mb-3">Update Checked Items</button>

          <button @click="insertCheckedItemsCommission" class="btn btn-primary mb-3 mx-3">Update Commissions</button>

          <table class="table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>Sku</th>
              <th>Title</th>
              <th>Sale Price</th>
              <th>Image</th>
              <th>Quantity</th>
              <th>Main Cost</th>
              <th>Sale Price(NEW)</th>
              <th>Total Commission</th>
              <th>Affiliate Commission</th>
              <th>Sub Affiliate Commission</th>
              <th>Select</th> <!-- Added -->
            </tr>
            </thead>
            <tbody>
            <tr v-for="product in productDetail" :key="product.id">
              <td>{{ product.id }}</td>
              <td v-if="product.sku">{{ product.sku }}</td>
              <td v-else>-</td>
              <td v-if="product.title">{{ product.title }}</td>
              <td v-else>-</td>
              <td v-if="product.sale_price">${{ product.sale_price }}</td>
              <td v-else>-</td>
              <td v-if="product.image"><img :src="'https://api.gocami.com/product_detail/' + product.image" width="75"/></td>
              <td v-else>-</td>
              <td v-if="product.quantity > 0">{{ product.quantity }}</td>
              <td v-else>Out Of stock</td>
              <td v-if="product.cost_display === 1">{{product.main_price}}</td>
              <td v-else>-</td>
              <td><input type="text" class="form-control" style="width:100px !important" v-model="product.new_price"/></td>
              <td><input type="text" class="form-control" style="width:100px !important" v-model="product.total_commission"/></td>
              <td><input type="text" class="form-control" style="width:100px !important" v-model="product.affiliate_commission"/>
              </td>
              <td><input type="text" style="width:100px !important" class="form-control"
                         v-model="product.sub_affiliate_commission"/></td>
              <td>
                <input type="checkbox" class="form-check-input" v-model="product.checked"/>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
  data() {
    return {
      selectedBrand: null,
      productDetail: [],
      product: {
        main_price: '',
        new_price: '',
        total_commissions: '',
        affiliate_commission: '',
        sub_affiliate_commission: '',
      },
      brands: [],

      products: [],
      childcategory: [],
      file: null

    };
  },

  created() {
    this.fetchAllData();
  },

  methods: {
    loadProductsByBrand() {
      if (this.selectedBrand) {
        this.fetchAllData(this.selectedBrand);
      }
    },

    fetchAllData(brandId) {
      return axiosInstance
          .get(`api/target-change/${brandId}`)
          .then(response => {
            this.productDetail = response.data.productDetail;
            this.brands = response.data.brands;
          })
          .catch(error => {
            console.error('Error fetching main data:', error);
          });
    },

    updateDescription(product) {
      console.log('Updating description for product:', product);

      axiosInstance
          .post(`api/update-target/${product.id}`, {
            main_price: product.main_price,
            new_price: product.new_price,
          })
          .then(response => {
            console.log('Details updated:', response.data);
            this.fetchAllData(this.selectedBrand);
          })
          .catch(error => {
            console.error('Error updating details:', error);
          });
    },

    insertCommission(product) {
      console.log('Updating commission for product:', product);

      axiosInstance
          .post(`api/insert-target-commission`, {
            product_id: product.id, // Send the product ID to the backend
            total_commissions: product.total_commissions,
            affiliate_commission: product.affiliate_commission,
            sub_affiliate_commission: product.sub_affiliate_commission,
          })
          .then(response => {
            console.log('Commissions inserted:', response.data);
            this.fetchAllData(this.selectedBrand); // Refresh the data after insertion
          })
          .catch(error => {
            console.error('Error inserting commissions:', error);
          });
    },


    updateCheckedItems() {
      const checkedProducts = this.productDetail.filter(product => product.checked);
      if (checkedProducts.length === 0) {
        alert("Please select at least one item to update.");
        return;
      }

      checkedProducts.forEach(product => {
        // Update the checked products
        this.updateDescription(product);
      });
    },

    insertCheckedItemsCommission() {
      const checkedProducts = this.productDetail.filter(product => product.checked);
      if (checkedProducts.length === 0) {
        alert("Please select at least one item to insert.");
        return;
      }

      checkedProducts.forEach(product => {
        // Insert a new commission record for each checked product
        this.insertCommission(product);
      });
    }

  },

}
</script>
