<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <h3 class="mb-5">Product Detail</h3>

          <div v-if="successMessage" class="alert alert-success w-100" role="alert">
            {{ successMessage }}
          </div>
          <div v-if="errorMessage" class="alert alert-danger" role="alert">
            {{ errorMessage }}
          </div>

          <div class="mb-3">
            <input type="date" v-model="receiveDate" class="form-control" required placeholder="Receive Date">
          </div>

          <div class="mb-3">
            <input type="text" v-model="userName" class="form-control" required placeholder="Name">
          </div>

          <button @click="updateCheckedQuantities" class="btn btn-success my-4" :disabled="!isFormValid">Update Checked Quantities</button>

          <div class="mb-3">
            <input type="text" v-model="enteredBarcode" @input="handleBarcodeInput" class="form-control w-100"
                   placeholder="Enter Barcode" style="width: 550px !important">
          </div>
          <div class="mb-3">
            <input type="text" v-model="enteredSku" @input="handleSkuInput" class="form-control w-100"
                   placeholder="Enter SKU" style="width: 550px !important">
          </div>


          <table class="table table-striped table-hover w-100">
            <thead class="table-dark">
            <tr>
              <th>Barcode</th>
              <th>Image</th>
              <th>Sku</th>
              <th>Title</th>
              <th>New Quantity</th>
              <th>Patch Number</th>
              <th>Zone</th>
              <th>Floor</th>
              <th>Stand</th>
              <th>Shelf</th>
              <th>Patch</th>
              <th>Select</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(product, index) in displayedProductDetails" :key="product.id">
              <td>
                <div class="input-group">
                  <input type="text" class="form-control" v-model="product.barcode"/>
                  <button @click="generateBarcodeForProduct(index)" class="btn btn-primary">Generate</button>
                </div>
              </td>
              <td>
                <img :src="'https://api.gocami.com/product_detail/' + product.image" class="img-thumbnail" width="75"/>
              </td>
              <td><input type="text" class="form-control" v-model="product.sku" required/></td>
              <td>{{ product.title }}</td>
              <td>
                <input type="number" class="form-control" v-model="product.newQuantity" readonly/>
                <div v-if="product.showInput">
                  <input type="number" class="form-control" v-model="product.additionalQuantity" min="1"/>
                  <button @click="incrementQuantity(product)" class="btn btn-primary">Add Quantity</button>
                </div>
                <button @click="toggleInput(product)" class="btn btn-success">+</button>
              </td>
              <td><input type="text" class="form-control" v-model="product.patchNumber" required/></td>
              <td>
                <select v-model="product.selectedZone" class="form-select" @change="loadFloors(index)">
                  <option v-for="zone in zones" :key="zone.id" :value="zone.id">{{ zone.zone_name }}</option>
                </select>
              </td>
              <td>
                <select v-model="product.selectedFloor" class="form-select" @change="loadStands(index)">
                  <option v-for="floor in product.floors" :key="floor.id" :value="floor.id">{{ floor.floor_name }}</option>
                </select>
              </td>
              <td>
                <select v-model="product.selectedStand" class="form-select" @change="loadShelves(index)">
                  <option v-for="stand in product.standes" :key="stand.id" :value="stand.id">{{ stand.stand_name }}</option>
                </select>
              </td>
              <td>
                <select v-model="product.selectedShelve" class="form-select" required>
                  <option v-for="shelve in product.shelves" :key="shelve.id" :value="shelve.id">{{ shelve.shelve_name }}</option>
                </select>
              </td>
              <td>
                <div v-for="(patch, patchIndex) in product.patches" :key="patchIndex" class="mb-3">
                  <input type="number" class="form-control mb-2" v-model="patch.newQuantity" placeholder="Quantity"/>
                  <input type="text" class="form-control mb-2" v-model="patch.patchNumber" placeholder="Patch Number" required/>
                  <input type="date" class="form-control mb-2" v-model="patch.expiryDate" placeholder="Expiry Date" required/>
                  <button @click="removePatchRow(product.id, patchIndex)" class="btn btn-danger btn-sm">Remove</button>
                </div>
                <button @click="addPatchRow(product.id)" class="btn btn-success btn-sm">+</button>
              </td>
              <td>
                <input type="checkbox" class="form-check-input" v-model="product.checked"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axiosInstance from '../../../../../axiosInstance';
import axiosRetry from 'axios-retry';

axiosRetry(axiosInstance, {retries: 3});

export default {
  data() {
    return {
      enteredSku: '',
      products: [
        {
          newQuantity: 0,
          additionalQuantity: 0,
          showInput: false
        },
      ],
      allProductDetails: [],
      selectedBrand: null,
      userName: '',
      receiveDate: '',
      brands: [],
      suppliers: [],
      enteredBarcode: '',
      successMessage: '',
      errorMessage: '',
      zones: [],
      displayedProductDetails: [],
      currentProductId: null
    };
  },

  created() {
    this.userLoad();
    this.loadZones();
  },

  watch: {
    enteredBarcode(newBarcode) {
      this.handleBarcodeInput(newBarcode);
    }
  },

  computed: {
    isFormValid() {
      const requiredFieldsFilled = this.receiveDate && this.userName;
      const currentRowValid = this.isCurrentRowValid;
      const allChecked = this.displayedProductDetails.every(product => product.checked);

      return requiredFieldsFilled && currentRowValid && allChecked;
    },

    isCurrentRowValid() {
      const currentRow = this.displayedProductDetails[this.displayedProductDetails.length - 1];
      return currentRow &&
          currentRow.barcode &&
          currentRow.sku &&
          currentRow.selectedZone &&
          currentRow.selectedFloor &&
          currentRow.selectedStand &&
          currentRow.selectedShelve &&
          currentRow.newQuantity !== null && // Allow 0 as a valid value
          currentRow.newQuantity !== undefined &&
          currentRow.checked;
    },
  },

  methods: {

    generateBarcodeForProduct(index) {
      const product = this.displayedProductDetails[index];
          product.barcode = 'GC' + '-' + product.sku;
        },

    loadZones() {
      axiosInstance.get('api/zones').then(({data}) => {
        this.zones = data;
      });
    },

    loadFloors(index) {
      const product = this.displayedProductDetails[index];
      if (product.selectedZone) {
        axiosInstance.get(`api/zones/${product.selectedZone}/floors`).then(({data}) => {
          product.floors = data;
        });
      }
    },

    loadStands(index) {
      const product = this.displayedProductDetails[index];
      if (product.selectedFloor) {
        axiosInstance.get(`api/floors/${product.selectedFloor}/stands`).then(({data}) => {
          product.standes = data;
        });
      }
    },

    loadShelves(index) {
      const product = this.displayedProductDetails[index];
      if (product.selectedStand) {
        axiosInstance.get(`api/stands/${product.selectedStand}/shelves`).then(({data}) => {
          product.shelves = data;
        });
      }
    },

    loadProductsByBrand() {
      if (this.selectedBrand) {
        this.userLoad(this.selectedBrand);
      } else {
        this.userLoad();
      }
    },

    userLoad() {
      const nextPage = `api/get-quantity-data-jarde`;
      axiosInstance.get(nextPage).then(({ data }) => {
        this.allProductDetails = data.products.map(product => ({
          ...product,
          checked: false,
          selectedZone: '',
          selectedFloor: '',
          selectedStand: '',
          selectedShelve: '',
          floors: [],
          standes: [],
          shelves: [],
          patches: [{ newQuantity: '', patchNumber: '', expiryDate: '' }]
        }));
        console.log("All Product Details:", this.allProductDetails); // Log to check product details
        this.suppliers = data.supplier;
      });
    },

    handleBarcodeInput() {
      const trimmedBarcode = this.enteredBarcode.trim().toLowerCase();
      console.log("Searching for barcode:", trimmedBarcode);

      const foundProduct = this.allProductDetails.find(product => {
        const productBarcode = product.barcode ? product.barcode.trim().toLowerCase() : '';
        return productBarcode === trimmedBarcode;
      });

      if (foundProduct) {
        this.processFoundProduct(foundProduct);
        this.enteredBarcode = ''; // Clear barcode input only if valid barcode is found
      } else {
        this.errorMessage = 'Barcode not found.';
        this.clearMessageAfterTimeout('errorMessage');
        // Do not clear enteredBarcode here
      }
    },

    handleSkuInput() {
      const trimmedSku = this.enteredSku.trim().toLowerCase();
      console.log("Searching for SKU:", trimmedSku);

      const foundProduct = this.allProductDetails.find(product => {
        const productSku = product.sku ? product.sku.trim().toLowerCase() : '';
        return productSku === trimmedSku;
      });

      if (foundProduct) {
        this.processFoundProduct(foundProduct);
        this.enteredSku = ''; // Clear SKU input only if valid SKU is found
      } else {
        this.errorMessage = 'SKU not found.';
        this.clearMessageAfterTimeout('errorMessage');
        // Do not clear enteredSku here
      }
    },

    processFoundProduct(foundProduct) {
      if (foundProduct) {
        const existingProduct = this.displayedProductDetails.find(product => product.id === foundProduct.id);

        if (existingProduct) {
          existingProduct.newQuantity = parseInt(existingProduct.newQuantity, 10) + 1;
        } else {
          this.displayedProductDetails.push({
            ...foundProduct,
            newQuantity: 0,
            checked: false,
            selectedZone: '',
            selectedFloor: '',
            selectedStand: '',
            selectedShelve: '',
            floors: [],
            standes: [],
            shelves: []
          });
        }

        // The barcode input is cleared in this method, if a valid product is found.
        setTimeout(() => {
          this.enteredBarcode = ''; // Clear barcode input after a valid search
        }, 1000);
      }
    },


    addPatchRow(productId) {
      const product = this.displayedProductDetails.find(p => p.id === productId);
      if (product) {
        product.patches.push({newQuantity: '', patchNumber: '', expiryDate: ''});
      }
    },

    removePatchRow(productId, patchIndex) {
      const product = this.displayedProductDetails.find(p => p.id === productId);
      if (product && product.patches.length > 1) {
        product.patches.splice(patchIndex, 1);
      }
    },

    toggleInput(product) {
      product.showInput = !product.showInput;
    },

    incrementQuantity(product) {
      const additionalQuantity = parseInt(product.additionalQuantity, 10) || 0;
      const currentNewQuantity = parseInt(product.newQuantity, 10) || 0;

      if (additionalQuantity > 0) {
        product.newQuantity = currentNewQuantity + additionalQuantity;

        console.log('After Increment - New Quantity:', product.newQuantity);

        product.additionalQuantity = '';
        product.showInput = false;
      } else {
        this.errorMessage = 'Additional quantity must be greater than 0.';
        this.clearMessageAfterTimeout('errorMessage');
      }
    },

    updateCheckedQuantities() {
      // Modify to include additional quantities if necessary
      if (!this.receiveDate || !this.userName) {
        this.errorMessage = 'Please fill in all required fields.';
        this.clearMessageAfterTimeout('errorMessage');
        return;
      }

      const checkedProducts = this.displayedProductDetails.filter(product => product.checked);
      if (checkedProducts.length === 0) {
        this.errorMessage = 'No products are selected!';
        this.clearMessageAfterTimeout('errorMessage');
        return;
      }

      let allRequests = checkedProducts.map(product => {
        const oldQuantity = product.quantity;
        const oldBarcode = product.barcode;
        const oldSku = product.sku;
        const productDetailId = product.id;

        const patches = product.patches
            .filter(patch => patch.patchNumber && patch.newQuantity && patch.expiryDate)
            .map(patch => ({
              patchNumber: patch.patchNumber,
              newQuantity: parseInt(patch.newQuantity, 10),
              expiryDate: patch.expiryDate,
            }));

        const requestData = {
          productDetailId,
          oldQuantity,
          newQuantity: parseInt(product.newQuantity, 10), // Ensure this is updated correctly
          receiveDate: this.receiveDate,
          userName: this.userName,
          invoiceNumber: this.invoiceNumber,
          oldBarcode,
          oldSku,
          barcode: product.barcode,
          sku: product.sku,
          shelve_id: product.selectedShelve || null,
          patches,
          jardeId: this.selectedBrand
        };

        console.log('Request Data:', requestData); // Debugging

        return axiosInstance.put('api/update-quantity-and-check-jarde', requestData);
      });

      Promise.allSettled(allRequests)
          .then(results => {
            const errors = results.filter(result => result.status === 'rejected');

            if (errors.length > 0) {
              this.errorMessage = 'Some products failed to update. Please try again.';
              this.clearMessageAfterTimeout('errorMessage');
              console.log(errors);
            } else {
              this.displayedProductDetails.forEach(product => {
                if (product.checked) {
                  product.quantity = parseInt(product.newQuantity, 10); // Update quantity
                  product.newQuantity = '';
                  product.checked = false;
                  product.patchNumber = '';
                  product.barcode = '';
                  product.sku = '';
                  product.patches = [{newQuantity: '', patchNumber: '', expiryDate: ''}];
                }
              });

              this.successMessage = 'Quantities and patches updated successfully!';
              this.clearMessageAfterTimeout('successMessage');
              this.userLoad(this.selectedBrand);
            }
          })
          .catch(error => {
            this.errorMessage = 'An error occurred during the update process. Please try again.';
            this.clearMessageAfterTimeout('errorMessage');
            console.log(error);
          });
    },


    addRow() {
      if (this.displayedProductDetails.length > 0 && !this.isCurrentRowValid) {
        this.errorMessage = 'Please fill out all fields in the current row before adding a new row.';
        this.clearMessageAfterTimeout('errorMessage');
        return;
      }

      const newProduct = {
        id: Date.now(),
        patches: [{newQuantity: '', patchNumber: '', expiryDate: ''}],
        checked: false,
        selectedZone: '',
        selectedFloor: '',
        selectedStand: '',
        selectedShelve: '',
        floors: [],
        standes: [],
        shelves: [],
      };

      this.displayedProductDetails.push(newProduct);
    },

    clearMessageAfterTimeout(messageType) {
      setTimeout(() => {
        this[messageType] = '';
      }, 5000);
    },
  },
};
</script>

<style scoped>

input, select {
  min-width: 150px !important;
}

.table th, .table td {
  vertical-align: middle;
  text-align: center;
}

.input-group .form-control {
  min-width: 320px !important;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
}

.btn {
  width: 100%;
}

@media (max-width: 768px) {
  .table {
    font-size: 0.9em;
  }

  .input-group .form-control,
  .form-select {
    max-width: 100px;
  }

  .btn {
    font-size: 0.8em;
  }
}

</style>
