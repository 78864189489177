<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <div>
            <h1>Affiliate Sales for 2024</h1>

            <!-- Affiliate Monthly Sales Table -->
            <table class="sales-table">
              <thead>
              <tr>
                <th>Name</th>
                <th v-for="(month, index) in months" :key="index">{{ month }}</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="affiliate in affiliates"
                  :key="affiliate.affiliate.id"
                  @click="toggleUsers(affiliate)"
                  class="affiliate-row"
              >
                <td>
                  {{ affiliate.affiliate.first_name }} {{ affiliate.affiliate.last_name }}
                  <template v-if="affiliate.showUsers">
                    <div class="user-info">
                      <strong>Users:</strong>
                      <table class="user-table">
                        <thead>
                        <tr>
                          <th>User Name</th>
                          <th v-for="(month, index) in months" :key="index">{{ month }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="user in affiliate.users" :key="user.id" class="user-row">
                          <td>{{ user.first_name }} {{ user.last_name }}</td>
                          <td v-for="(sales, monthIndex) in Object.entries(user.monthly_sales)" :key="monthIndex">
                            <!-- Directly use monthIndex to get the sales without adjusting with -1 -->
                            {{ formatCurrency(sales[1].before) }} / {{ formatCurrency(sales[1].after) }}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>
                </td>
                <td v-for="(sales, monthIndex) in Object.entries(affiliate.monthly_sales)" :key="monthIndex">
                  {{ formatCurrency(sales[1].before) }} / {{ formatCurrency(sales[1].after) }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
  data() {
    return {
      affiliates: [],
      months: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
      currentMonth: new Date().getMonth() + 1, // Get the current month (1-12)
    };
  },
  methods: {
    fetchAffiliateSales() {
      axiosInstance.get('api/affiliates-invoices', {params: {from: '2024-01-01', to: '2024-12-31'}})
          .then(response => {
            console.log('API Response:', response.data); // Log the response data
            // Initialize showUsers for each affiliate
            this.affiliates = response.data.map(affiliate => ({
              ...affiliate,
              showUsers: false // Initialize showUsers property
            }));
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
    },
    toggleUsers(affiliate) {
      // Toggle the showUsers property for the clicked affiliate
      affiliate.showUsers = !affiliate.showUsers;
    },
    formatCurrency(value) {
      return `$${parseFloat(value).toFixed(2)}`;
    },
    getTotalSales(monthlySales, type) {
      return Object.values(monthlySales).reduce((total, sales) => total + sales[type], 0);
    }
  },
  mounted() {
    this.fetchAffiliateSales();
  }
};
</script>


<style scoped>
.container {
  max-width: 900px;
  margin: 0 auto;
}

.sales-table {
  width: 100%; /* Full width of the parent */
  border-collapse: collapse;
  margin-top: 10px;
}

.sales-table th,
.sales-table td {
  border: 1px solid #ddd;
  padding: 12px; /* Increased padding for better spacing */
  text-align: left;
}

.sales-table th {
  background-color: #f2f2f2;
}

.affiliate-row {
  cursor: pointer; /* Change cursor to pointer for clickable rows */
  background-color: #ffffff; /* Default background for affiliates */
}

.affiliate-row:hover {
  background-color: #f1f1f1; /* Light gray on hover */
}

.user-row {
  background-color: #f9f9f9; /* Slightly different background for user rows */
}

.user-info {
  padding-left: 20px; /* Indentation for user info */
  padding-top: 10px; /* More padding for visual separation */
  padding-bottom: 10px; /* More padding for visual separation */
  border-top: 1px solid #ddd; /* Optional: add a top border for clarity */
}

.user-info strong {
  font-weight: bold; /* Bold user names */
}

.user-info span {
  margin-left: 15px; /* Space between month sales info */
}
</style>
